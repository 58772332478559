/*
 * All existing Fund
 */

const gooroo = {
  id: 1,
  name: "GOOROO FUNDO DE INVESTIMENTO",
  cnpj: "51295720000162"
};

const santaBarbara = {
  id: 2,
  name: "SANTA BÁRBARA FUNDO DE INVESTIMENTO",
  cnpj: "44680424000110"
};

/*
 * Enum
 */

export const FundsEnum = {
  GOOROO: gooroo,
  SANTA_BARBARA: santaBarbara
};

