type AdminType = {
  id: number;
  name: string;
  description: string;
};

/*
 * All existing Administrator
 */

const limine = {
  id: 1,
  name: "LIMINE",
  description: "Limine"
};

/*
 * Enum
 */

export const AdministratorEnum = {
  LIMINE: limine
};

export function getAdministratorEnumNameById(id: number): string {
  for (const key in AdministratorEnum) {
    if (Object.prototype.hasOwnProperty.call(AdministratorEnum, key)) {
      const administrator = AdministratorEnum[
        key as keyof typeof AdministratorEnum
      ] as AdminType;
      if (administrator.id === id) {
        return administrator.name;
      }
    }
  }
  return "";
}

