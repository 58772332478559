type BankingType = {
  id: number;
  name: string;
  description: string;
};

/*
 * All existing Bankings
 */

const moneyPlus = {
  id: 1,
  name: "MONEY_PLUS",
  description: "Money Plus"
};

const LECCA = {
  id: 2,
  name: "LECCA",
  description: "LECCA"
};

const BRP = {
  id: 3,
  name: "BRP",
  description: "BRP"
};

/*
 * Enum
 */

export const BankingEnum = {
  MONEY_PLUS: moneyPlus,
  LECCA: LECCA,
  BRP: BRP
};

export function getBankingEnumNameById(id: number): string {
  for (const key in BankingEnum) {
    if (Object.prototype.hasOwnProperty.call(BankingEnum, key)) {
      const banking = BankingEnum[
        key as keyof typeof BankingEnum
      ] as BankingType;
      if (banking.id === id) {
        return banking.name;
      }
    }
  }
  return "";
}

