import HttpClient from "./http-client";

export default class EletronicSignatureService extends HttpClient {
  private static classInstance?: EletronicSignatureService;

  private constructor() {
    super(process.env.VUE_APP_ELETRONIC_SIGNATURE_URL!);
  }

  public static getInstance(): EletronicSignatureService {
    if (!this.classInstance) {
      this.classInstance = new EletronicSignatureService();
    }
    return this.classInstance;
  }

  public async showDocument(documentKey: string): Promise<any> {
    return this.axiosCall({
      method: "GET",
      url: `/signature/show-document/${documentKey}`,
    });
  }
}
